<template>
  <div>
    <v-card class="pa-4">
      <v-row class="pa-3">
        <p class="text-2xl ma-5">Veuillez configurer les paramètres désirés</p>
      </v-row>
      <v-card-text>
        <hr />
        <v-row class="mt-3">
          <v-col cols="6" valign="center" justify="left">
            <v-radio-group
              v-model="partnersSelectionType"
              @change="updateParams"
              row
            >
              <v-radio
                label="Pour tous les partenaires"
                value="all-partners"
              ></v-radio>
              <v-radio
                label="Choisir certains partenaires"
                value="select-partners"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" valign="center" justify="left">
            <v-autocomplete
              v-if="partnersSelectionType == 'select-partners'"
              outlined
              v-model="selectedPartnersList"
              :items="partnersList"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Sélectionner les partenaires"
              multiple
              deletable-chips
              item-text="name"
              item-value="code"
              @input="updateParams"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <hr />
        <v-row class="mt-3">
          <v-col cols="6" valign="center" justify="left">
            <v-radio-group
              v-model="periodSelectionType"
              @change="updateParams"
              row
            >
              <v-radio label="Pour un seul jour" value="one-day"></v-radio>
              <v-radio
                label="Pour une période donnée"
                value="a-period"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" valign="center" justify="center">
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-if="periodSelectionType == 'one-day'"
                  v-model="day"
                  label="Date"
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="dates"
                  label="Dates"
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="periodSelectionType == 'one-day'"
                v-model="day"
                @input="updateParams"
              ></v-date-picker>
              <v-date-picker
                v-else
                v-model="dates"
                @input="updateParams"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col cols="12" valign="center" justify="center">
            <v-checkbox
              v-model="forceApi"
              label="Force API calls (ne pas utiliser le cache)"
              @change="updateParams"
            ></v-checkbox>
            <v-checkbox
              v-model="ifEmpty"
              label="If empty (ne pas réintérroger l'api s'il y a déjà des data)"
              @change="updateParams"
            ></v-checkbox>
            <v-checkbox
              v-model="debug"
              label="Debug (afficher plus d'info dans le journal de logs dans l'interface Airflow)"
              @change="updateParams"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";

export default {
  name: "PartnerGetAndStoreDaily",
  props: {
    partnersList: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.day = this.getDefaultDay();
    console.log("this.day", this.day);
    this.dates = this.getDefaultDateRange();
    this.updateParams();
  },
  methods: {
    updateParams() {
      // this.menuDate = false;
      let params = {};
      let errors = [];
      let texts = [];
      let command = "python -m crawlers partners_get_and_store_infos ";
      if (this.partnersSelectionType == "select-partners") {
        if (this.selectedPartnersList.length == 0) {
          errors.push(
            "Veuillez sélectionner au moins un partenaire, ou bien choisir 'Pour tous les partenaires'"
          );
        } else {
          if (this.selectedPartnersList.length > 0) {
            let partnersStr = this.selectedPartnersList.join(",");
            params["partners"] = this.selectedPartnersList.join(",");
            texts.push(`Pour les partenaires suivant: ${partnersStr}`);
            command += `--partners=${partnersStr} `;
          }
        }
      } else {
        texts.push("Pour tous les partenaires.");
      }

      if (this.periodSelectionType == "one-day") {
        let nDays = this.calculateNDays(this.day);
        console.log("nDays", nDays);
        if (nDays > -1) {
          errors.push("Veuillez choisir une date antérieure à aujourd'hui.");
        } else if (nDays == -1) {
          texts.push(`Pour la journée d'hier.`);
        } else {
          nDays = Math.abs(nDays);
          params["n_days"] = nDays;
          texts.push(`Pour le ${this.day}, il y a ${nDays} jours.`);
          command += `--n-days=${nDays} `;
        }
      } else {
        if (this.dates.length != 2) {
          errors.push(
            "Veuillez choisir une période de dates ou bien choisir 'Pour un seul jour'."
          );
        } else {
          params["date_from"] = this.dates[0];
          params["date_to"] = this.dates[1];
          texts.push(
            `Pour la période du ${this.dates[0]} au ${this.dates[1]}.`
          );
          command += `--date-from=${this.dates[0]} --date-to=${this.dates[1]} `;
        }
      }

      params["force_api"] = this.forceApi;
      if (this.forceApi) {
        texts.push("Force API => Oui. (= n'utilisera pas de cache)");
        command += "--force-api ";
      } else {
        texts.push("Force API => Non. (= utilisera le cache si existant)");
      }

      params["if_empty"] = this.ifEmpty;
      if (this.ifEmpty) {
        texts.push(
          "If empty => Oui. (= ne pas réintérroger l'api s'il y a déjà des data)"
        );
        command += "--if-empty ";
      } else {
        texts.push(
          "If empty => Non. (= réintérroger l'api même s'il y a déjà des data et elles seront écraser)"
        );
      }

      params["debug"] = this.debug;
      if (this.debug) {
        texts.push(
          "Debug => Oui. (= afficher plus d'info dans le journal de logs dans l'interface Airflow)"
        );
        command += "--debug ";
      } else {
        texts.push(
          "Debug => Non. (= Ne pas afficher plus d'info dans le journal de logs)"
        );
      }

      let paramsInfos = {
        params: params,
        errors: errors,
        texts: texts,
        command: command,
      };
      this.$emit("onUpdate", paramsInfos);
    },
    calculateNDays(day) {
      let date_1 = new Date(day); // new Date('10/25/2021');
      let date_2 = new Date();
      let difference = date_1.getTime() - date_2.getTime();
      // this.nDays = Math.abs(Math.ceil(difference / (1000 * 3600 * 24)));
      this.nDays = Math.ceil(difference / (1000 * 3600 * 24));
      return this.nDays;
    },
    getDefaultDateRange() {
      const today = new Date();
      const yesterday = new Date(today);
      const threeDaysAgo = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      threeDaysAgo.setDate(today.getDate() - 3);
      return [
        threeDaysAgo.toISOString().slice(0, 10),
        yesterday.toISOString().slice(0, 10),
      ];
    },
    getDefaultDay() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return yesterday.toISOString().slice(0, 10);
    },
  },
  data() {
    return {
      forceApi: false,
      ifEmpty: false,
      debug: false,
      selectedPartnersList: [],
      menuDate: false,
      nDays: null,
      day: null,
      dates: [
        /*"2023-03-26", "2023-03-28"*/
      ],
      periodSelectionType: "one-day", // one-day, a-period
      partnersSelectionType: "all-partners", // all-partners, select-partners

      icons: {
        mdiCalendar,
      },
    };
  },
};
</script>
<!-- 
    <v-dialog v-model="isOpenCreateCronDialog" width="70vw">
      <create-cron-modal
        @openDialogFunction="ocCreateCronDialog"
        :openDialogIsOpen="isOpenCreateCronDialog"
      ></create-cron-modal>
    </v-dialog>
  -->
