<template>
  <div>
    <v-card class="pa-4">
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="6" valign="center" justify="left">
            Pour quelles définition de data GA ?
          </v-col>
          <v-col cols="6" valign="center" justify="left">
            <v-autocomplete
              outlined
              v-model="selectedWhich"
              :items="['site', 'source_medium', 'source_medium_device']"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Which"
              @input="updateParams"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <hr />
        <v-row class="mt-3">
          <v-col cols="6" valign="center" justify="left"> Timespan </v-col>
          <v-col cols="6" valign="center" justify="left">
            <v-autocomplete
              outlined
              v-model="selectedTimespan"
              :items="['daily', 'weekly', 'monthly', 'yearly']"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Sélectionner un timespan"
              @input="updateParams"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <hr />
        <v-row class="mt-3">
          <v-col cols="6" valign="center" justify="left">
            <v-radio-group
              v-model="partnersSelectionType"
              @change="updateParams"
              row
            >
              <v-radio label="Pour tous les sites" value="all-sites"></v-radio>
              <v-radio
                label="Choisir certains sites"
                value="select-sites"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" valign="center" justify="left">
            <v-autocomplete
              v-if="partnersSelectionType == 'select-sites'"
              outlined
              v-model="selectedSiteList"
              :items="siteList"
              :menu-props="{ maxHeight: '400', zIndex: 13 }"
              label="Sélectionner des sites"
              multiple
              deletable-chips
              item-text="domain"
              item-value="domain"
              @input="updateParams"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <hr />
        <v-row class="mt-3">
          <v-col cols="6" valign="center" justify="left">
            <v-radio-group
              v-model="periodSelectionType"
              @change="updateParams"
              row
            >
              <v-radio label="Pour un seul jour" value="one-day"></v-radio>
              <v-radio
                label="Pour une période donnée"
                value="a-period"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" valign="center" justify="center">
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-if="periodSelectionType == 'one-day'"
                  v-model="day"
                  label="Date"
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="dates"
                  label="Dates"
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="periodSelectionType == 'one-day'"
                v-model="day"
                @input="updateParams"
              ></v-date-picker>
              <v-date-picker
                v-else
                v-model="dates"
                @input="updateParams"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col cols="12" valign="center" justify="center">
            <v-checkbox
              v-model="forceApi"
              label="Force API calls (ne pas utiliser le cache)"
              @change="updateParams"
            ></v-checkbox>
            <v-checkbox
              v-model="errorRecovery"
              label="Error recovery"
              @change="updateParams"
            ></v-checkbox>
            <v-checkbox
              v-model="ifEmpty"
              label="Seulement s'il n'y a pas déjà de données"
              @change="updateParams"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";

export default {
  name: "GaGetAndStore",
  props: {
    siteList: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.day = this.getDefaultDay();
    console.log("this.day", this.day);
    this.dates = this.getDefaultDateRange();
    this.updateParams();
  },
  methods: {
    updateParams() {
      // this.menuDate = false;
      let params = {};
      let errors = [];
      let texts = [];
      let command = "python -m crawlers ga_get_and_store ";

      params["which"] = this.selectedWhich;
      texts.push(
        `Pour la définition de data GA suivante: ${this.selectedWhich}`
      );
      command += `--which=${this.selectedWhich} `;

      params["timespan"] = this.selectedTimespan;
      texts.push(`Timespan: ${this.selectedTimespan}`);
      command += `--timespan=${this.selectedTimespan} `;

      if (this.partnersSelectionType == "select-sites") {
        if (this.selectedSiteList.length == 0) {
          errors.push(
            "Veuillez sélectionner au moins un site, ou bien choisir 'Pour tous les sites'"
          );
        } else {
          if (this.selectedSiteList.length > 0) {
            let sitesStr = this.selectedSiteList.join(",");
            params["domains"] = this.selectedSiteList.join(",");
            texts.push(`Pour les sites suivant: ${sitesStr}`);
            command += `--domains=${sitesStr} `;
          }
        }
      } else {
        texts.push("Pour tous les sites.");
      }

      if (this.periodSelectionType == "one-day") {
        let nDays = this.calculateNDays(this.day);
        console.log("nDays", nDays);
        if (nDays > -1) {
          errors.push("Veuillez choisir une date antérieure à aujourd'hui.");
        } else if (nDays == -1) {
          texts.push(`Pour la journée d'hier.`);
        } else {
          nDays = Math.abs(nDays);
          params["n_days"] = nDays;
          texts.push(`Pour le ${this.day}, il y a ${nDays} jours.`);
          command += `--n-days=${nDays} `;
        }
      } else {
        if (this.selectedTimespan != "daily") {
          errors.push(
            "Impossible de choisir une période de dates si le timespan est autre que 'daily'. (cf ga_get_and_store_main.py)"
          );
        } else if (this.dates.length != 2) {
          errors.push(
            "Veuillez choisir une période de dates ou bien choisir 'Pour un seul jour'."
          );
        } else {
          params["date_from"] = this.dates[0];
          params["date_to"] = this.dates[1];
          texts.push(
            `Pour la période du ${this.dates[0]} au ${this.dates[1]}.`
          );
          command += `--date-from=${this.dates[0]} --date-to=${this.dates[1]} `;
        }
      }

      params["force_api"] = this.forceApi;
      if (this.forceApi) {
        texts.push("Force API => Oui. (= n'utilisera pas de cache)");
        command += "--force-api ";
      } else {
        texts.push("Force API => Non. (= utilisera le cache si existant)");
      }

      params["error_recovery"] = this.errorRecovery;
      if (this.errorRecovery) {
        texts.push("Ne revenir que sur les site en erreur");
        command += "--error-recovery ";
      }

      params["if_empty"] = this.ifEmpty;
      if (this.ifEmpty) {
        texts.push("Seulement si aucune données déjà récupérées");
        command += "--if-empty ";
      }

      let paramsInfos = {
        params: params,
        errors: errors,
        texts: texts,
        command: command,
      };
      this.$emit("onUpdate", paramsInfos);
    },
    calculateNDays(day) {
      let date_1 = new Date(day); // new Date('10/25/2021');
      let date_2 = new Date();
      let difference = date_1.getTime() - date_2.getTime();
      // this.nDays = Math.abs(Math.ceil(difference / (1000 * 3600 * 24)));
      this.nDays = Math.ceil(difference / (1000 * 3600 * 24));
      return this.nDays;
    },
    getDefaultDateRange() {
      const today = new Date();
      const yesterday = new Date(today);
      const threeDaysAgo = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      threeDaysAgo.setDate(today.getDate() - 3);
      return [
        threeDaysAgo.toISOString().slice(0, 10),
        yesterday.toISOString().slice(0, 10),
      ];
    },
    getDefaultDay() {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return yesterday.toISOString().slice(0, 10);
    },
  },
  data() {
    return {
      selectedSiteList: [],
      selectedTimespan: "daily",
      selectedWhich: "site",
      forceApi: false,
      errorRecovery: false,
      ifEmpty: false,
      menuDate: false,
      nDays: null,
      day: null,
      dates: [
        /*"2023-03-26", "2023-03-28"*/
      ],
      periodSelectionType: "one-day", // one-day, a-period
      partnersSelectionType: "all-sites", // all-sites, select-sites
      icons: {
        mdiCalendar,
      },
    };
  },
};
</script>
